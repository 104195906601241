<template>
  <view-wrapper :breadcrumb="false">
    <div class="content">
      <a-tabs @change="tabs.tabClick" v-model:activeKey="tabs.activeKey" tab-position="left">
        <a-tab-pane key="basic" tab="基本设置">
          <template v-if="!basic.state.goFinished">
            <div class="block-title">基本设置</div>
            <div class="flex">
              <div class="block-content__left" style="flex: 1; margin-right: 80px">
                <h1 class="info-block__title">个人信息</h1>
                <div class="info-block__list">
                  <div class="flex info-item">
                    <label class="info-item-label">姓名</label>
                    <span class="info-item-span">{{ userData.name }}</span>
                  </div>
                  <div class="flex info-item">
                    <label class="info-item-label">手机号</label>
                    <span class="info-item-span">{{ userData.cellphone }}</span>
                  </div>
                </div>
                <h1 class="info-block__title">公司信息</h1>
                <ul class="info-block__list">
                  <li class="flex info-item info-item_one cursor-pointer" @click="basic.state.goFinished = true">
                    <span style="color: #ff4d00">{{ isAuthenticated ? '已认证' : '未认证' }}</span>
                    <RightOutlined />
                  </li>
                </ul>
              </div>
              <div class="block-content__right" style="flex: 1">
                <div v-if="isAuthenticated">
                  <h1 class="info-block__title">头像</h1>
                  <div>
                    <e-upload v-model="basic.state.userPortrait" :max="1"></e-upload>
                    <a-button @click="basic.uploadHeadPortrait.click">
                      <upload-outlined></upload-outlined>上传
                    </a-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="block-title" style="margin-bottom: 24px">认证</div>
            <a-form :model="basic.form.data" layout="vertical" style="width: 368px">
              <a-form-item label="详细地址">
                <a-input
                  :maxlength="50"
                  :disabled="isSubmit"
                  v-model:value="basic.form.data.address"
                  placeholder="请输入详细地址"
                  autocomplete="off"
                />
              </a-form-item>
              <a-form-item label="固定电话">
                <div class="flex">
                  <a-input
                    :maxlength="4"
                    :disabled="isSubmit"
                    style="width: 100px"
                    v-model:value="basic.form.data.areaCode"
                    placeholder="区号"
                    autocomplete="off"
                  />
                  <a-input
                    :maxlength="8"
                    :disabled="isSubmit"
                    style="width: 260px"
                    v-model:value="basic.form.data.telePhone"
                    placeholder="请输入固定电话"
                    @change="() => (basic.form.data.telePhone = basic.form.data.telePhone.replace(/[\D]/g, ''))"
                    autocomplete="off"
                  />
                </div>
              </a-form-item>
              <a-form-item label="公司全称">
                <a-input
                  :disabled="isSubmit"
                  :maxlength="30"
                  v-model:value="basic.form.data.name"
                  placeholder="请输入公司全称"
                  autocomplete="off"
                />
              </a-form-item>
              <a-form-item label="上传营业执照" style="flex-wrap: nowrap">
                <e-upload
                  :disabled="isSubmit"
                  v-model="basic.form.data.businessLicenseUrls"
                  dir="license"
                  :max="1"
                ></e-upload>
              </a-form-item>
              <a-form-item label="纳税人识别号">
                <a-input
                  :disabled="isSubmit"
                  :maxlength="20"
                  @change="
                    () =>
                      (basic.form.data.businessLicenceId = basic.form.data.businessLicenceId
                        .replace(/[\W]/g, '')
                        .toLocaleUpperCase())
                  "
                  v-model:value="basic.form.data.businessLicenceId"
                  placeholder="请输入纳税人识别号"
                  autocomplete="off"
                />
              </a-form-item>
              <a-form-item label="法人姓名">
                <a-input
                  :maxlength="20"
                  :disabled="isSubmit"
                  v-model:value="basic.form.data.juridicalPersonName"
                  placeholder="请输入法人姓名"
                  autocomplete="off"
                />
              </a-form-item>
              <a-form-item v-if="!isSubmit">
                <a-button type="primary" :loading="basic.form.submit.loading" @click="basic.form.submit.click"
                  >认证</a-button
                >
              </a-form-item>
            </a-form>
          </template>
        </a-tab-pane>
        <a-tab-pane key="pass" tab="更改密码">
          <div class="block-title" style="margin-bottom: 24px">更改密码</div>
          <a-alert
            style="margin-bottom: 24px"
            message="新密码6-12位，不能使用空格、中文，至少包含数字/字母/字符2种组合。"
            type="warning"
          />
          <a-form :model="password.form.data" layout="vertical" style="width: 368px">
            <a-form-item label="旧密码">
              <a-input
                v-model:value="password.form.data.originalPassword"
                placeholder="请输入旧密码"
                :type="isShowOldPass ? 'text' : 'password'"
                autocomplete="off"
              >
                <template #suffix>
                  <EyeOutlined v-if="isShowOldPass" @click="isShowOldPass = !isShowOldPass" />
                  <EyeInvisibleOutlined v-else @click="isShowOldPass = !isShowOldPass" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item label="新密码">
              <a-input
                v-model:value="password.form.data.newPassword"
                @chenage="password.passwordChange"
                placeholder="请输入新密码"
                :type="isShowPass ? 'text' : 'password'"
                autocomplete="off"
              >
                <template #suffix>
                  <EyeOutlined v-if="isShowPass" @click="isShowPass = !isShowPass" />
                  <EyeInvisibleOutlined v-else @click="isShowPass = !isShowPass" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item label="确认新密码">
              <a-input
                v-model:value="password.form.data.checkPass"
                placeholder="请再次输入新密码"
                :type="isShowPass ? 'text' : 'password'"
                autocomplete="off"
              />
              <a-alert
                v-if="
                  password.form.data.newPassword &&
                  password.form.data.checkPass &&
                  password.form.data.checkPass !== password.form.data.newPassword
                "
                message="确认新密码和新密码不一致"
                type="error"
                show-icon
              />
            </a-form-item>
            <a-form-item>
              <a-button :loading="submitPasswordLoading" @click="password.confirm.click" type="primary">确认</a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane v-if="userData.role && userData.role.code === 'M'" key="team" tab="团队设置">
          <template v-if="isAuthenticated">
            <div class="flex justify-between items-center" style="margin-bottom: 24px">
              <div class="block-title">团队设置</div>
              <a-button type="primary" @click="team.addMemberWrap.open">
                <template #icon>
                  <PlusOutlined />
                </template>
                添加成员
              </a-button>
            </div>
            <e-table
              pagination
              :paging="team.table.paging"
              rowKey="id"
              :data-source="team.table.data"
              :columns="team.table.columns"
              class="teamTable"
              height="650"
              :method="team.table.get"
            >
              <template #role="{ text, record }">
                <span>{{ text }}</span>
                <UserOutlined
                  v-if="record.role.code === 'M'"
                  style="color: #0d8d8d; transform: rotateY(180deg); margin-right: 8px"
                />
              </template>
              <template #operation="{ record }">
                <a-button
                  v-if="record.role.code === 'M'"
                  type="link"
                  class="operation-model"
                  @click="team.manageChangeWrap.open"
                  >转移</a-button
                >
                <a-button v-else type="link" class="operation-model" @click="team.discard.click(record)">注销</a-button>
                <a-button
                  type="link"
                  v-if="record.ssoUser.wechatUser"
                  class="operation-model"
                  @click="team.unbind.click(record)"
                  >解绑</a-button
                >
                <a-button type="link" v-else class="operation-model" @click="team.bind.click(record)"
                  >微信绑定</a-button
                >
              </template>
            </e-table>
          </template>
          <template v-else>
            <a-alert type="warning" show-icon closable style="margin-bottom: 8px">
              <template #message>
                <div class="align-center">
                  <span>完善及时认证信息，可以完成团队设置！</span>
                  <a-button type="link" @click="team.goFinished.click">去认证</a-button>
                </div>
              </template>
            </a-alert>
          </template>
        </a-tab-pane>
        <a-tab-pane v-if="userData.role && userData.role.code === 'M'" key="manage" tab="经营设置">
          <div class="block-title" style="margin-bottom: 24px">经营设置</div>
          <a-form :model="manage.form.data" layout="vertical" style="width: 368px">
            <a-form-item label="质保时间">
              <a-input-number :precision="0" v-model:value="manage.form.data.guaranteePeriod" :min="0" :max="36" />
              <span style="margin-left: 12px">个月</span>
            </a-form-item>
            <template v-if="basic.form.data.businessType.code === 'W'">
              <a-form-item label="经营品牌" @click="manage.brandsWrap.visible = true">
                <a-input
                  :value="manage.form.data.vehicleBrands.join('/')"
                  class="input-arrow-right"
                  readonly
                  placeholder="经营品牌"
                >
                  <template #suffix>
                    <RightOutlined />
                  </template>
                </a-input>
              </a-form-item>
            </template>
            <template v-else>
              <a-form-item label="保养易损件" @click="manage.partsWrap.visible = true">
                <a-input
                  :value="
                    manage.form.data.parts
                      .concat(manage.form.data.customKeywords)
                      .map((item) => item.message || item.keyword)
                      .join('/')
                  "
                  class="input-arrow-right"
                  readonly
                  placeholder="经营品牌"
                >
                  <template #suffix>
                    <RightOutlined />
                  </template>
                </a-input>
              </a-form-item>
            </template>
            <a-form-item label="退货地址">
              <a-input
                v-model:value="manage.form.data.returnAddress"
                placeholder="请输入退货详细地址"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item>
              <a-button type="primary" :loading="manage.form.submit.loading" @click="manage.form.submit.click"
                >保存</a-button
              >
            </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </div>
  </view-wrapper>
  <a-drawer
    title="管理角色转移"
    :visible="team.manageChangeWrap.visible"
    width="480"
    @close="team.manageChangeWrap.visible = false"
  >
    <div class="flex-direction-column justify-between">
      <a-radio-group v-model:value="team.manageChangeWrap.state.toUserId" class="flex-direction-column w-full">
        <a-radio
          v-for="(role, index) in team.manageChangeWrap.table.data"
          :key="role.id + index"
          :style="radioStyle"
          :value="role.id"
          >{{ role.name }}</a-radio
        >
      </a-radio-group>
    </div>
    <div class="drawer-footer">
      <a-button style="margin-right: 8px" @click="team.manageChangeWrap.visible = false">取消</a-button>
      <a-button type="primary" @click="team.manageChangeWrap.confirm">确认</a-button>
    </div>
  </a-drawer>
  <a-drawer
    title="添加成员"
    :visible="team.addMemberWrap.visible"
    width="480"
    @close="team.addMemberWrap.visible = false"
  >
    <a-form layout="vertical" style="margin-top: 16px">
      <a-form-item label="姓名">
        <a-input v-model:value="team.addMemberWrap.state.name" placeholder="请输入名字" autocomplete="off" />
      </a-form-item>
      <a-form-item label="手机号">
        <a-input v-model:value="team.addMemberWrap.state.cellphone" placeholder="请输入手机号" autocomplete="off" />
      </a-form-item>
      <a-form-item label="密码">
        <a-input v-model:value.trim="team.addMemberWrap.state.password" placeholder="请输入密码" autocomplete="off" />
      </a-form-item>
      <a-form-item style="margin: 10px 0">
        <a-button type="primary" @click="team.addMemberWrap.addUser.click">添加</a-button>
      </a-form-item>
    </a-form>
    <div style="line-height: 24px">密码6-12位，不能使用空格、中文，至少包含数字/字母/字符2种组合。</div>
  </a-drawer>
  <drawer-brands
    v-model:brands="manage.form.data.vehicleBrands"
    v-model:visible="manage.brandsWrap.visible"
  ></drawer-brands>
  <drawer-parts
    v-model:parts="manage.form.data.parts"
    v-model:customParts="manage.form.data.customKeywords"
    v-model:visible="manage.partsWrap.visible"
  ></drawer-parts>
  <a-modal v-model:visible="bindModal.visible" title="二维码" :footer="null" width="350px">
    <img :src="bindModal.imgUrl" width="300" />
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAjax, useStore, useTable } from '@vue-mfe/utils'
import type { UserState } from '@vue-mfe/utils'
import moment from 'moment'

import {
  RightOutlined,
  UserOutlined,
  UploadOutlined,
  PlusOutlined,
  EyeOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons-vue'
import {
  message,
  Tabs as ATabs,
  TabPane as ATabPane,
  Spin as ASpin,
  Drawer as ADrawer,
  Alert as AAlert,
  Radio as ARadio,
  RadioGroup as ARadioGroup,
  Tag as ATag,
  InputNumber as AInputNumber,
  Modal as AModal
} from 'ant-design-vue'
import { DrawerBrands, DrawerParts } from '@vue-mfe/components'

import type { ColumnProps } from 'ant-design-vue/es/table/interface'

interface PasswordState {
  form: {
    data: {
      originalPassword: string
      newPassword: string
      checkPass: string
    }
  }
  passwordChange: () => void
  confirm: {
    click: () => void
  }
}
const columns = [
  { title: '团队成员', dataIndex: 'name' },
  { title: '手机号码', dataIndex: 'cellphone' },
  { title: '角色', dataIndex: 'role.message', slots: { customRender: 'role' } },
  { title: '上次登录', dataIndex: 'lastLoginDatetime' },
  { title: '状态', dataIndex: 'hiringStatus.message', slots: { customRender: 'status' } },
  { title: '操作', align: 'center', slots: { customRender: 'operation' } }
] as ColumnProps[]
export default defineComponent(
  {
    // props: ['model', 'finished'],
    components: {
      ATabs,
      ATabPane,
      AInputNumber,
      ASpin,
      ADrawer,
      AAlert,
      ARadio,
      ARadioGroup,
      ATag,
      AModal,
      RightOutlined,
      UserOutlined,
      UploadOutlined,
      PlusOutlined,
      EyeOutlined,
      EyeInvisibleOutlined,
      DrawerBrands,
      DrawerParts
    },
    setup () {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const tab = route.query.tab as string
      const finished = route.query.finished
      let isShowPass = ref(false)
      let isShowOldPass = ref(false)
      const getsupplierConvert = (res: any) => {
        const data = res[0]
        if (data.contactCellphone) {
          const [areaCode, telePhone] = data.contactCellphone.split('-')
          data.areaCode = areaCode
          data.telePhone = telePhone
        }
        data.businessLicenseUrls = []
        if (data.businessLicenseUrl) {
          data.businessLicenseUrls.push(data.businessLicenseUrl)
        } else {
          data.businessLicenseUrl = ''
        }
        return data
      }

      const params = () => {
        const data = { ...basic.form.data } as any
        data.contactCellphone = `${data.areaCode}-${data.telePhone}`
        data.businessLicenseUrl = data.businessLicenseUrls[0]
        return data
      }

      let userData = reactive<Partial<UserState>>(
        {
          name: '',
          cellphone: '',
          role: {
            code: ''
          }
        }
      )
      const { run: getUser } = useAjax<UserState>(
        {
          action: 'GET /supplier/security/user',
          success (data) {
            userData = Object.assign(userData, data[0])
            if (data[0].icon) {
              basic.state.userPortrait = []
              basic.state.userPortrait.push(data[0].icon)
            }
          }
        }
      )

      const [getSupplier, submitSupplier] = [
        useAjax(
          {
            action: 'GET /supplier/security/currentsupplier',
            convert: { client: getsupplierConvert },
            lazy: true
          }
        ),
        useAjax({ action: 'PUT /supplier/submit', params, lazy: true })
      ]

      const { loading: submitPasswordLoading, run: submitPassword } = useAjax(
        {
          action: 'POST /supplier/security/password',
          params: () => ({ ...password.form.data }),
          success () {
            message.info('密码修改成功，请重新登录！', 1)
            setTimeout(
              () => {
                router.replace({ name: 'admin-authenticate' }).finally(
                  () => {
                    store.actions.reset()
                    localStorage.removeItem('ENOQUOTE_ROLE')
                  }
                )
              },
              1000
            )
          },
          lazy: true
        }
      )

      const [changemanager] = [useAjax({ action: 'GET /user', params: () => ({ pageSize: 999 }) })]

      const submitBusinessinfo = useAjax(
        {
          action: 'PUT /supplier/businessinfo',
          params: () => ({ ...manage.form.data }),
          lazy: true
        }
      )

      const tabs = reactive(
        {
          activeKey: tab || 'basic',
          tabClick () {
            if (tabs.activeKey === 'basic') basic.state.goFinished = false
          }
        }
      )

      const basic = reactive(
        {
          form: {
            data: {
              address: '',
              contactCellphone: '',
              name: '',
              businessLicenseUrl: '',
              businessLicenseUrls: [],
              businessLicenceId: '',
              juridicalPersonName: '',
              areaCode: '',
              telePhone: '',
              businessType: {
                code: ''
              },
              status: {
                code: ''
              }
            },
            get () {
              getSupplier.run().then(
                (res) => {
                  basic.form.data = Object.assign(basic.form.data, res.data.data[0])
                  manage.form.data = Object.assign(manage.form.data, res.data.data[0])
                }
              )
            },

            submit: {
              loading: submitSupplier.loading,
              click () {
                if (
                  !basic.form.data.address ||
                  !basic.form.data.areaCode ||
                  !basic.form.data.telePhone ||
                  !basic.form.data.name ||
                  !basic.form.data.businessLicenseUrls[0] ||
                  !basic.form.data.businessLicenceId ||
                  !basic.form.data.juridicalPersonName
                ) {
                  return message.warning('请填写完整认证信息！')
                }
                submitSupplier.run().then(
                  () => {
                    message.success('已提交')
                    basic.state.goFinished = false
                    basic.form.get()
                  }
                )
              }
            }
          },
          state: {
            userPortrait: [] as any[],
            goFinished: finished || false
          },
          uploadHeadPortrait: {
            click () {
              const submitUser = useAjax(
                {
                  action: 'PUT /user',
                  params: () => ({
                    ...store.state.user,
                    icon: basic.state.userPortrait[0] || ''
                  }),
                  success () {
                    message.success('头像更新成功')
                    location.reload()
                  },
                  lazy: true
                }
              )
              submitUser.run()
            }
          }
        }
      )

      const password = reactive<PasswordState>(
        {
          form: {
            data: {
              originalPassword: '',
              newPassword: '',
              checkPass: ''
            }
          },
          passwordChange () {},
          confirm: {
            click () {
              if (!password.form.data.originalPassword) {
                return message.warning('初始密码不能为空')
              }
              if (!password.form.data.newPassword) {
                return message.warning('新密码不能为空')
              }
              if (!password.form.data.checkPass) {
                return message.warning('确认新密码不能为空')
              }
              if (password.form.data.newPassword === password.form.data.checkPass) {
                submitPassword()
              }
            }
          }
        }
      )

      const teamTable = useTable(
        {
          ajax: {
            get: {
              action: 'GET /user',
              params: () => ({ rsexp: 'id,name,cellphone,hiringStatus,role,lastLoginDatetime,ssoUser' }),
              convert: {
                client (data) {
                  return data.map(
                    (item) => {
                      item.lastLoginDatetime = item.lastLoginDatetime
                        ? moment(item.lastLoginDatetime).fromNow()
                        : '无登录记录'
                      return item
                    }
                  )
                }
              }
            }
          },
          columns
        }
      )
      const team = reactive(
        {
          table: teamTable,
          discard: {
            click (row: any) {
              const discard = useAjax(
                {
                  action: 'PUT /user/:userId/disable',
                  params: row.id,
                  lazy: true
                }
              )
              discard.run().then(
                () => {
                  message.success('注销成功')
                  team.table.get && team.table.get()
                  team.manageChangeWrap.table.get()
                }
              )
            }
          },
          manageChangeWrap: {
            visible: false,
            state: {
              toUserId: ''
            },
            table: {
              data: [] as any[],
              get () {
                changemanager.run().then(
                  (res) => {
                    team.manageChangeWrap.table.data = res.data.data.filter(
                      (item: { role: any }) => item.role.code !== 'M'
                    )
                  }
                )
              }
            },
            confirm () {
              if (!team.manageChangeWrap.state.toUserId) return message.info('请选择转移人员')
              const change = useAjax(
                {
                  action: 'PUT /user/:toUserId/changemanager',
                  params: () => team.manageChangeWrap.state.toUserId,
                  lazy: true
                }
              )
              change.run().then(
                () => {
                  message.success('管理员已转移')
                  location.reload()
                }
              )
            },
            open () {
              team.manageChangeWrap.visible = true
              team.manageChangeWrap.state.toUserId = ''
            }
          },
          addMemberWrap: {
            visible: false,
            state: {
              cellphone: '',
              name: '',
              password: ''
            },
            open () {
              team.addMemberWrap.state.cellphone = ''
              team.addMemberWrap.state.name = ''
              team.addMemberWrap.state.password = ''
              team.addMemberWrap.visible = true
            },
            addUser: {
              click () {
                if (
                  !team.addMemberWrap.state.name ||
                  !team.addMemberWrap.state.cellphone ||
                  !team.addMemberWrap.state.password
                ) {
                  return message.info('手机号或姓名或密码未填写')
                }
                // let reg = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/
                // if (!reg.test(team.addMemberWrap.state.password)) {
                //   return message.info('密码需至少包含数字、字母、符号中的2种')
                // }
                const addUser = useAjax(
                  {
                    action: 'POST /user',
                    params: () => ({
                      cellphone: team.addMemberWrap.state.cellphone,
                      name: team.addMemberWrap.state.name,
                      password: team.addMemberWrap.state.password
                    }),
                    success () {
                      team.addMemberWrap.visible = false
                      team.table.get && team.table.get()
                      team.manageChangeWrap.table.get()
                    },
                    lazy: true
                  }
                )
                addUser.run()
              }
            }
          },
          goFinished: {
            click () {
              tabs.activeKey = 'basic'
              basic.state.goFinished = true
            }
          },
          bind: {
            click: (row: any) => {
              useAjax(
                {
                  action: 'GET /user/:userId/wechat/bind/url',
                  params: () => row.id,
                  success (data) {
                    bindModal.imgUrl = data[0]
                    bindModal.visible = true
                  }
                }
              )
            }
          },
          unbind: {
            click: (row: any) => {
              useAjax(
                {
                  action: 'PUT /user/:userId/wechat/unbind',
                  params: () => row.id,
                  success () {
                    team.table.get()
                  }
                }
              )
            }
          }
        }
      )
      const bindModal = reactive(
        {
          visible: false,
          imgUrl: ''
        }
      )
      const manage = reactive(
        {
          form: {
            data: {
              guaranteePeriod: 0,
              vehicleBrands: [] as any[],
              parts: [] as any[],
              customKeywords: [] as any[],
              returnAddress: ''
            },
            init () {
              manage.form.data.guaranteePeriod = 0
              manage.form.data.vehicleBrands = []
              manage.form.data.parts = []
              manage.form.data.returnAddress = ''
            },
            submit: {
              loading: false,
              click () {
                manage.form.submit.loading = true
                submitBusinessinfo.run().then(
                  () => {
                    manage.form.submit.loading = false
                    manage.form.init()
                    basic.form.get()
                  }
                )
              }
            }
          },
          brandsWrap: {
            visible: false
          },
          partsWrap: {
            visible: false
          }
        }
      )
      const radioStyle = reactive(
        {
          display: 'block',
          height: '50px',
          lineHeight: '30px',
          paddingTop: '10px',
          paddingBottom: '10px',
          borderBottomStyle: 'solid',
          borderBottomColor: '#e8e8e8',
          borderBottomWidth: '1px'
        }
      )
      const isAuthenticated = computed(() => ['A', 'D'].includes(basic.form.data.status.code))
      const isSubmit = computed(() => ['A', 'D', 'S'].includes(basic.form.data.status.code))
      basic.form.get()
      getUser()
      team.manageChangeWrap.table.get()
      return {
        userData,
        basic,
        tabs,
        password,
        submitPasswordLoading,
        team,
        bindModal,
        manage,
        radioStyle,
        isAuthenticated,
        isSubmit,
        isShowPass,
        isShowOldPass
      }
    }
  }
)
</script>

<style scoped>
.icon-plus-text {
  font-size: 18px;
  margin-right: 10px;
}

.content {
  min-height: 700px;
  padding: 24px 40px 50px 4px;
  background-color: #fff;
}

.content :deep(.ant-input),
.content :deep(.ant-btn) {
  height: 40px;
  font-size: 16px;
}
.content :deep(.ant-form-item) {
  margin-bottom: 24px;
}
.block-title {
  height: 28px;
  line-height: 28px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.info-block__title {
  margin: 24px 0;
  font-size: 18px;
  line-height: 22px;
}

.info-item {
  height: 30px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #d9d9d9;
}

.info-item_one {
  padding: 0 12px;
  justify-content: space-between;
}

.info-item-label {
  width: 50px;
  text-align: right;
  margin-right: 24px;
}

.info-item-right-arrow {
  font-size: 16px;
  color: #bfbfbf;
}

.user-logo {
  width: 144px;
  height: 144px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.check-list-box {
  display: flex;
  flex-wrap: wrap;
}

.check-list-box__item {
  width: 120px;
}
.code-modal .ant-modal-confirm-btns {
  display: none;
}
</style>
